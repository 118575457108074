import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "/",
    component: () => import("@/layar"),
    redirect: "/infoManagement/topicList",
    children: [
      {
        path: "/userManagement/userList",
        name: "userList",
        component: () => import("@/views/userManagement/userList"),
      },
      {
        path: "/userManagement/shareList",
        name: "shareList",
        component: () => import("@/views/userManagement/shareList"),
      },  
      {
        path: "/userManagement/userList/userInfo",
        name: "userInfo",
        component: () => import("@/views/userManagement/userListInfo"),
      },
      {
        path: "/userManagement/recruitJoin",
        name: "recruitJoin",
        component: () => import("@/views/userManagement/recruitJoin"),
      },
      {
        path: "/userManagement/callWhiteList",
        name: "callWhiteList",
        component: () => import("@/views/userManagement/callWhiteList"),
      },
      // 话题管理
      {
        path: "/infoManagement/topicList",
        name: "topicList",
        component: () => import("@/views/infoManagement/topicList"),
      },
      // banner管理
      {
        path: "/infoManagement/bannerList",
        name: "bannerList",
        component: () => import("@/views/infoManagement/bannerList"),
      },
      //信息列表
      {
        path: "/infoManagement/articleList",
        name: "articleList",
        component: () => import("@/views/infoManagement/articleList"),
      },
      //信息发布
      {
        path: "/infoManagement/articleList/articlePublish",
        name: "articlePublish",
        component: () => import("@/views/infoManagement/articlePublish"),
      },
      // Ipfs管理
      {
        path: "/NFTManagement/nftList",
        name: "nftList",
        component: () => import("@/views/NFTManagement/nftList"),
      },
      // 白名单管理
      {
        path: "/NFTManagement/whiteList",
        name: "whiteList",
        component: () => import("@/views/NFTManagement/whiteList"),
      },
      // 白名单管理
      {
        path: "/NFTManagement/itemList",
        name: "itemList",
        component: () => import("@/views/NFTManagement/itemList"),
      },
      // nft商城
      {
        path: "/NFTManagement/nftShop",
        name: "nftShop",
        component: () => import("@/views/NFTManagement/nftShop"),
      },
      // 房间管理
      {
        path: "/roomManagement/roomList",
        name: "roomList",
        component: () => import("@/views/roomManagement/roomList"),
      },
      // 房间标签
      {
        path: "/roomManagement/roomLabels",
        name: "roomLabels",
        component: () => import("@/views/roomManagement/roomLabels"),
      },
      // 礼物管理
      {
        path: "/giftManagement/giftList",
        name: "giftList",
        component: () => import("@/views/giftManagement/giftList"),
      },
      // 幸运礼物
      {
        path: "/giftManagement/luckyGift",
        name: "luckyGift",
        component: () => import("@/views/giftManagement/luckyGift"),
      },
      // 宝箱礼物
      {
        path: "/giftManagement/treasureGift",
        name: "treasureGist",
        component: () => import("@/views/giftManagement/treasureGift"),
      },
      // 用户道具管理
      {
        path: "/giftManagement/userPropsList",
        name: "userPropsList",
        component: () => import("@/views/giftManagement/userPropsList"),
      },
      {
        path: "/giftManagement/giftGiveOut",
        name: "giftGiveOut",
        component: () => import("@/views/giftManagement/giftGiveOut"),
      },
      {
        path: "/giftManagement/timeYieldConfig",
        name: "timeYieldConfig",
        component: () => import("@/views/giftManagement/timeYieldConfig"),
      },
      // 任务管理
      {
        path: "/taskManagement/taskList",
        name: "taskList",
        component: () => import("@/views/taskManagement/taskList/taskList"),
      },
      // 任务详情
      {
        path: "/taskManagement/taskList/taskInfo",
        name: "taskListInfo",
        component: () => import("@/views/taskManagement/taskList/taskListInfo"),
      },
      // 任务标签
      {
        path: "/taskManagement/taskLabels",
        name: "taskLabels",
        component: () => import("@/views/taskManagement/taskLabels"),
      },
      // 转盘配置
      {
        path: "/taskManagement/turntableConfig",
        name: "turntableConfig",
        component: () => import("@/views/taskManagement/turntableConfig"),
      },
      {
        path: "/systemManagement/roleList",
        name: "roleList",
        component: () => import("@/views/systemManagement/roleList"),
      },
      {
        path: "/systemManagement/permissionAssign",
        name: "permissionAssign",
        component: () => import("@/views/systemManagement/permissionAssign"),
      },
      {
        path: "/systemManagement/accountList",
        name: "accountList",
        component: () => import("@/views/systemManagement/accountList"),
      },
        {
          path: "/systemManagement/apiList",
          name: "apiList",
          component: () => import("@/views/systemManagement/apiList"),
        },
      // 应用配置
      {
        path: "/systemManagement/appConfig",
        name: "appConfig",
        component: () => import("@/views/systemManagement/appConfig"),
      },
      {
        path: "/rechargeManagement/goodsList",
        name: "goodsList",
        component: () => import("@/views/rechargeManagement/goodsList"),
      },  
      {
        path: "/rechargeManagement/rechargeRecord",
        name: "rechargeRecord",
        component: () => import("@/views/rechargeManagement/rechargeRecord"),
      },
      // 运营管理
      {
        path: "/operationsManagement/countryGroup",
        name: "countryGroup",
        component: () => import("@/views/operationsManagement/countryGroup"),
      },
      {
        path: "/operationsManagement/roomDivision",
        name: "roomDivision",
        component: () => import("@/views/operationsManagement/roomDivision"),
      },  
      {
        path: "/logManagement/talkLog",
        name: "talkLog",
        component: () => import("@/views/logManagement/talkLog"),
      },
      {
        path: "/roomManagement/roomChest",
        name: "roomChest",
        component: () => import("@/views/roomManagement/roomChest"),
      },
      {
        path:"/gameManagement/gameList",
        name:"gameList",
        component:()=>import("@/views/gameManagement/gameList")
      },
      {
        path:"/verbalManagement/verbalList",
        name:"verbalList",
        component:()=>import("@/views/verbalManagement/verbalList")
      },
      {
        path:"/verbalManagement/robotsList",
        name:"robotsList",
        component:()=>import("@/views/verbalManagement/robotsList")
      }
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    return { x: 0, y: 0 };
  },
  mode: "hash",
  base: "/admin",
  routes,
});

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token");
  if (to.name == "login" || token) {
    next();
  } else {
    next({ name: "login" });
  }
});

export default router;
